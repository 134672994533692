<template>
  <div>
    <b-row v-for="comment in comments" :key="comment.id">
      <b-col sm="8">
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              @input="resize"
              @focus="resize"
              v-model.trim="comment.observation"
            ></textarea>
          </template>
        </ResizeAuto>
      </b-col>
      <b-col>{{ comment.creation_date | FormatToDateTime }}</b-col>
      <b-col style="max-width: 70px">
        <button-delete @click="deleteObservation(comment.id)"></button-delete>
      </b-col>
    </b-row>
    <div v-if="comments.length == 0">
      <p>No tiene comentarios.</p>
    </div>
    <div v-if="allows_crud" class="row mt-1">
      <div class="col" style="text-align: left">
        <b-button class="mr-1" size="sm" @click="addComment"
          >Agregar Comentario</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import ResizeAuto from "@/components/reusable/ResizeAuto";
// import moment from 'moment';

export default {
  name: "SectionObservations",
  components: {
    ResizeAuto,
  },
  props: {
    section_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comments: [],
    };
  },
  methods: {
    fetchComments() {
      this.$restful
        .Get(`/teaching/section-observation/?section=${this.section_id}`)
        .then((response) => {
          this.comments = response;
        });
    },
    save() {
      this.comments.forEach((element) => {
        if (isNaN(element.id)) {
          this.createComment(element);
        } else {
          this.updateComment(element);
        }
      });
      this.$emit("saved");
    },
    createComment(comment) {
      this.$restful
        .Post("/teaching/section-observation/", comment)
        .then((response) => {
          const index = this.comments.findIndex((x) => x.id == comment.id);
          if (index != -1) this.comments.splice(index, 1, response);
          this.$swal
            .mixin({
              toast: true,
              position: "top-start",
              showConfirmButton: false,
              timer: 3000,
              title: "Comentario guardado.",
            })
            .fire();
        });
    },
    updateComment(comment) {
      this.$restful
        .Put(`/teaching/section-observation/${comment.id}/`, comment)
        .then((response) => {
          const index = this.comments.findIndex((x) => x.id == comment.id);
          if (index != -1) this.comments.splice(index, 1, response);
          this.$swal
            .mixin({
              toast: true,
              position: "top-start",
              showConfirmButton: false,
              timer: 3000,
              title: "Comentario actualizado.",
            })
            .fire();
        });
    },
    addComment() {
      let comment = {
        id: generateUniqueId(),
        observation: "",
        section: this.section_id,
      };
      this.comments.push(comment);
    },
    deleteObservation(id) {
      if (!isNaN(id)) {
        this.$restful
          .Delete(`/teaching/section-observation/${id}/`)
          .then(() => {
            let index = this.comments.findIndex((x) => x.id == id);
            if (index != -1) this.comments.splice(index, 1);
          });
      } else {
        let index = this.comments.findIndex((x) => x.id == id);
        if (index != -1) this.comments.splice(index, 1);
      }
    },
  },
  computed: {
  },
  watch: {},
  created() {
    this.fetchComments();
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
}
</style>